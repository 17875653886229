import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class BeforeLoginGuardService implements CanActivate, CanActivateChild {

  loggedIn: boolean = false;

  constructor(private auth: AuthService, private router: Router) {
    //GET LOGGED IN STATUS
    this.auth.authStatus.subscribe(value => this.loggedIn = value);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    //IF LOGGED IN NAVIGATE TO THE DASHBOARD
    if(this.loggedIn) {
      this.router.navigateByUrl('/dashboard/dashboard');
      return false;
    }

    //IF NOT LOGGED IN ACTIVATE THE ROUTE
    return !this.loggedIn;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  boolean {
    return this.canActivate(route, state);
  }

}
