import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

// import { environment } from '@environments/environment';
// import { AccountService } from '@app/_services';

@Injectable({
    providedIn: 'root'
  })
export class JwtInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('request2');
        const auth_token = localStorage.getItem("auth_token");
        // const userToken = 'secure-user-token';
        const modifiedReq = request.clone({ 
          headers: request.headers.set('Authorization', `Bearer ${auth_token}`),
        });
        // console.log(request);

        return next.handle(modifiedReq);
    }
}
