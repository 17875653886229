import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

	constructor() { }

	handleToken(token: string) {
		this.set(token);
	}

	set(token: string) {
		localStorage.setItem('auth_token', token);
	}

	setUserData(userData: object) {
		localStorage.setItem('user_data', JSON.stringify(userData));
	}

	get() {
		return localStorage.getItem('auth_token');
	}

	getUserData() {
		return JSON.parse(localStorage.getItem('user_data') || '{}');
	}

	remove() {
		localStorage.removeItem('auth_token');
		localStorage.removeItem('user_data');
		if(localStorage.getItem('recording_id')) {
			localStorage.removeItem('recording_id');
		}
	}

	isValid() {
		const token = this.get();
		const userData: any = this.getUserData();
		if(token && userData)
		{
			return true;
		}
		return false;
	}

	isLoggedIn() {
		return this.isValid();
	}
  
}
