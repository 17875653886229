import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AfterLoginGuardService implements CanActivate, CanActivateChild {

  loggedIn: boolean = false;

  constructor(private auth: AuthService, private router: Router) {
    //GET LOGGED IN STATUS
    this.auth.authStatus.subscribe(value => this.loggedIn = value);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
     
    //IF LOGGED IN THEN ACTIVATE ROUTE     
    if (this.loggedIn) {
      return this.loggedIn; 
    }

    //IF NOT LOGGED IN NAVIGATE TO THE LOGIN PAGE
    this.router.navigateByUrl('/login');
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  boolean {
    return this.canActivate(route, state);
  }

}
