import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string, allWords: boolean = false): string {
    if (typeof value !== 'string') return value;

    if (!value) return value;
    
    if (allWords) {
      return value
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    } else {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
  }

}
