import {
HttpEvent,
HttpHandler,
HttpInterceptor,
HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
constructor(private loggerService: LoggerService) {}

intercept(
    request: HttpRequest<any>,
    next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // console.log(request);
        return next.handle(request).pipe(
            catchError((requestError) => {
                // console.log(requestError);
                this.loggerService.add({
                    severity: 'error',
                    status: requestError.status,
                    summary: `HTTP Error - ${requestError.status}`,
                    detail: requestError.error && requestError.error.message,
                  });
                return throwError(() => new Error(requestError.error.message));
            })
        );
    }
}
