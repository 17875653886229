import { Injectable } from '@angular/core';

import{ TokenService } from './token.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedInStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.token.isLoggedIn());
  private loggedInUserData: BehaviorSubject<any> = new BehaviorSubject<any>(this.token.getUserData());
  tokenExpStatus: BehaviorSubject<{status: boolean}> = new BehaviorSubject<{status: boolean}>({status: false});

  authStatus = this.loggedInStatus.asObservable();
  authUserData = this.loggedInUserData.asObservable();

  constructor(private token: TokenService, private router: Router, private http: HttpClient) { }

  //LOGOUT AND REDIRECT USER
  logout() {
  	this.token.remove();
  	this.changeAuthStatus(false, {});
  	this.router.navigateByUrl('/login');
  }

  //IMMEDIATELY UPDATE LOGIN STATUS
  changeAuthStatus(value: boolean, userUdata: any) {
   	this.loggedInStatus.next(value);
    this.loggedInUserData.next(userUdata);
  }

  //TO HANDLE EXPIRED TOKEN
  handleTokenExpired(data: any){
    this.tokenExpStatus.next(data);
  }

}
