import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, Observable, of, Subject, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  isLoading = new Subject<boolean>(); 
// private _isLoading = new BehaviorSubject<boolean>(false);
// isLoading: Observable<boolean> = this._isLoading.pipe(
//   switchMap(isLoading =>{
//     if(!isLoading){
//       return of(false)
//     }
//     return of(true).pipe(delay(1000))
//   } )
// );
show() {
  this.isLoading.next(true);
}
hide(){
  this.isLoading.next(false);
}
}
