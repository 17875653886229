import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login/login.component';
import { BeforeLoginGuardService } from './_helpers/guards/before-login-guard.service';
import { AfterLoginGuardService } from './_helpers/guards/after-login-guard.service';

const routes: Routes = [
  {path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule), canActivateChild: [BeforeLoginGuardService]},
  {path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule), canActivateChild: [BeforeLoginGuardService]},
  {path: 'forgot', loadChildren: () => import('./forgot/forgot.module').then(m => m.ForgotModule), canActivateChild: [BeforeLoginGuardService]},
  {path: 'dashboard', loadChildren: () => import('./dashboard-module/dashboard/dashboard.module').then(m=>m.DashboardModule), canActivateChild: [AfterLoginGuardService]},
  {path: 'experiment', loadChildren: () => import('./experiment/experiment.module').then(m=>m.ExperimentModule), canActivateChild: [AfterLoginGuardService]},
  {path: 'verify-auth/:token/:recordingId', loadChildren: () => import('./verify-auth/verify-auth.module').then(m=>m.VerifyAuthModule)},
  {path: '', redirectTo:'login',pathMatch: 'full'}
];

@NgModule({
  imports: [CommonModule,RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
