import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  constructor(private router : Router, private auth: AuthService) {}

  add(message:any) {
    console.log(message);
    if(message.status === 401){
      //CHANGE AUTHENTICATION STATUS
      this.auth.logout();
      const data = {status: true};
      this.auth.handleTokenExpired(data);
      //this.router.navigate(['/login']);
    }
    else if(message.status === 403) {
      this.router.navigate(['/dashboard/dashboard'])
    }
  }
}
